import bg1 from '../assets/images/bg1.jpg';
import Carousel from 'react-bootstrap/Carousel';

function Home() {
  const car_img_style = {
    "maxHeight": "400px",
    "minHeight": "400px",
  };

  const style_caption = {textShadow: "-2px -2px 4px #000, 2px 2px 4px #000, 2px -2px 4px #000, -2px 2px 4px #000"};

  return (
    <div className="App-view">
      <center style={{ width: "75%"}}>
        Open Versatile Mobility Platform (<b>OpenVMP</b>) implements multi-modal mobility mechanisms that perform arbitrary tasks individually or collectively.
      </center>

      <Carousel style={{margin: "40px", width: "100%"}}>
        <Carousel.Item bg="dark">
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg1}
            alt="Versatile Mobility"
          />
          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Versatile Mobility</h3>
            <p>
            Only OpenVMP robots both drive and walk. Climb poles, trees, pipes... Climb wires, cables... Crawl through tunnels...
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg1}
            alt="Unparalleled Mobility"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Unparalleled Mobility</h3>
            <p>
              OpenVMP robots are designed to join bodies to form even bigger robots.
              Once bodies are joined, the mobility performance improves even further.
              3 robots can climb over a fence, a swarm can pass any obstacle.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg1}
            alt="Docking Stations"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Docking Stations</h3>
            <p>
             OpenVMP robots can dock to cars, trucks, walls for storage and long distance fleet transfers.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg1}
            alt="Swarm Behavior"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Swarm Behavior</h3>
            <p>
              Robots in a fleet cooperate to achieve goals, distribute tasks between themselves.
              Multi-robot docking stations for trucks and vans.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg1}
            alt="Modular Platform"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Modular Platform</h3>
            <p>
            The first generation has 2 module types:
            Front and rear payloads to perform tasks (for each robot in a fleet).
            Top payload to enhance swarm capabilities (for one robot per fleet).
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg1}
            alt="Open Platform"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Open Platform</h3>
            <p>
              Open source (Apache 2.0) platform
              with unified fleet control and management.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg1}
            alt="Reference Designs"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Reference Designs</h3>
            <p>
              Reference robot designs will be available also.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            style={car_img_style}
            src={bg1}
            alt="Robot Autonomy"
          />

          <Carousel.Caption bg="dark" style={style_caption}>
            <h3>Robot Autonomy</h3>
            <p>
            Perform autonomously “without control” by customers.
            But, initially, “with control” by tech personnel remotely.
            Fully autonomous behavior later when it makes financial sense.
            It doesn't make financial sense now while &gt;200M are unemployed worldwide.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      {/* <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Open Versatile Mobility Platform (OpenVMP) implements multi-modal mobility mechanisms that perform arbitrary tasks individually or collectively.
          </p>
          <a
            className="App-link"
            href="https://gitbub.com/openvmp/openvmp"
            target="_blank"
            rel="noopener noreferrer"
          >
            Repository
          </a>
        </header>
      </div> */}
    </div>
  );
}

export default Home;
