import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import robot_don1 from '../assets/images/robot-don1.png';
import robot_oskol1 from '../assets/images/robot-oskol1.png';

function Products() {
  const style_container = {
    paddingTop: "15px",
    paddingBottom: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    // width: '18rem',
  };
  const style_row = {
    alignItems: 'start',
    justifyContent: 'center',
  };
  const style_card = {
    // width: '18rem',
    // border: "none",
    margin: "20px",
  };
  const style_body = {
    padding: "25px",
    // backgroundColor: "#282c34",
  };
  const style_image = {
    paddingBottom: "20px",
  };
  const style_title = {
    fontSize: 16,
    color: '#ccc',
    paddingBottom: "20px",
    fontWeight: 1200,
  };
  const style_link = {
    display: 'block',
    fontSize: 16,
    color: '#ccc',
    // textDecoration: "none",
  };
  const style_text = {
    display: 'block',
    fontSize: 14,
    color: '#ccc',
    // textDecoration: "none",
  };

  return (
    <div className="App-view">
        <h4>Robot platforms</h4>

        OpenVMP robots could be built using one of the below platforms:
        <Container style={style_container}>
          <Row xs={1} md={2} lg={3} xl={4} style={style_row}>
            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <a href="https://github.com/openvmp/openvmp/blob/main/platform/src/openvmp_robot_don1/">
                    <Card.Img variant="top" style={style_image} src={robot_don1} />
                  </a>
                  <Card.Title style={style_title}>
                    <a style={style_link} href="https://github.com/openvmp/openvmp/blob/main/platform/src/openvmp_robot_don1/">Don1</a>
                  </Card.Title>
                  <Card.Text style={style_text}>
                    This is the first OpenVMP platform published.
                    It's rather big as no effort is put into optimizing size and efficiency.
                    But the big size allows it to be effective at performing some tasks,
                    that would require multiple OpenVMP units of future platforms to join their bodies
                    to be able to accomplish the same tasks.
                    <a style={style_link} href="https://github.com/openvmp/openvmp/blob/main/platform/src/openvmp_robot_don1/">View the source code</a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Img variant="top" style={style_image} src={robot_oskol1} />
                  <Card.Title style={style_title}>
                    Oskol1
                  </Card.Title>
                  <Card.Text style={style_text}>
                    This is the next scheduled OpenVMP platform.
                    It is expected to demonstrate how small OpenVMP units could be
                    while still relying on generally available and cost-effective components
                    such as NEMA-17 stepper motors.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

          </Row>
        </Container>

        <h4>Fixed modules</h4>

        The following modules could be hardwired on top of OpenVMP robots:

        <Container style={style_container}>
          <Row xs={1} md={2} lg={3} xl={4} style={style_row}>
            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Brain boost
                  </Card.Title>
                  <Card.Text style={style_text}>
                    This modules carries additional computational power packaged in multiple instances of
                    Intel NUC form factor.
                    For most of missions, at least one robot in the fleet must have this module installed
                    to perform required cerebral functions.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Sensory overload
                  </Card.Title>
                  <Card.Text style={style_text}>
                    This module carries a wide range of sensors for surveillance and reconnaissance purposes.
                    It's installed as a fixed module to provide mechanical protection to sensitive sensors
                    (such as the geiger counter) and to meet dimensions requires of sensitive antennas.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <h4>Swappable modules</h4>

        The following modules could be connected and disconnected by OpenVMP robots themselves at the front or at the back of each robot:

        <Container style={style_container}>
          <Row xs={1} md={2} lg={3} xl={4} style={style_row}>
            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Claw
                  </Card.Title>
                  <Card.Text style={style_text}>
                    This modules carries a general purpose manipulator.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Poker
                  </Card.Title>
                  <Card.Text style={style_text}>
                    This modules carries a general purpose linear actuator with switchable tools for a wide range of mechanical tasks (from pressing a button to poking a cable with needle for data sniffing).
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Telescope
                  </Card.Title>
                  <Card.Text style={style_text}>
                    This modules carries a telescopic camera for long distance reconnaissance.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Wireless charging
                  </Card.Title>
                  <Card.Text style={style_text}>
                    This module allows OpenVMP robots
                    to charge themselves from city power lines for long term missions
                    away from the mission command.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Liquid dispenser
                  </Card.Title>
                  <Card.Text style={style_text}>
                    This module allows OpenVMP units
                    to dispense and spray liquids (such as water or fertilizers)
                    in the direction away from the middle wheel (for agricultural and similar purposes).
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Paint dispenser
                  </Card.Title>
                  <Card.Text style={style_text}>
                    This module is similar to the liquid dispenser
                    but it's design to spray liquids in the same direction where the central wheel is
                    to target the poles, pipes or tunnels that the robot itself is climbing
                    (for industrial and similar purposes).
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
    </div>
  );
}

export default Products;
