import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import partcad_logo from '../assets/images/partcad.png';
import robot_oskol1 from '../assets/images/robot-oskol1.png';

function Technologies() {
  const style_container = {
    paddingTop: "15px",
    paddingBottom: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    // width: '18rem',
  };
  const style_row = {
    alignItems: 'start',
    justifyContent: 'center',
  };
  const style_card = {
    // width: '18rem',
    // border: "none",
    margin: "20px",
  };
  const style_body = {
    padding: "25px",
    // backgroundColor: "#282c34",
  };
  const style_image = {
    paddingBottom: "20px",
  };
  const style_title = {
    fontSize: 16,
    color: '#ccc',
    paddingBottom: "20px",
    fontWeight: 1200,
  };
  const style_link = {
    display: 'block',
    fontSize: 16,
    color: '#ccc',
    // textDecoration: "none",
  };
  const style_text = {
    display: 'block',
    fontSize: 14,
    color: '#ccc',
    // textDecoration: "none",
  };

  return (
    <div className="App-view">
        <h4>OpenVMP technologies</h4>

        In the process of building OpenVMP, the following generic purpose technologies were born:
        <Container style={style_container}>
          <Row xs={1} md={2} lg={3} xl={4} style={style_row}>
            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <a href="https://github.com/openvmp/partcad/">
                    <Card.Img variant="top" style={style_image} src={partcad_logo} />
                  </a>
                  <Card.Title style={style_title}>
                    <a style={style_link} href="https://github.com/openvmp/partcad/">PartCAD</a>
                  </Card.Title>
                  <Card.Text style={style_text}>
                    The first package manager for CAD models.
                    <a style={style_link} href="https://github.com/openvmp/partcad/">View the source code</a>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

          </Row>
        </Container>

    </div>
  );
}

export default Technologies;
