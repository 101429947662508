import social_linkedin from '../assets/images/social-linkedin.png';
import social_github from '../assets/images/social-github.png';
import social_twitter from '../assets/images/social-twitter.png';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

function Footer() {
  const style_container = {
    paddingTop: "40px",
    paddingBottom: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    // width: '18rem',
  };
  const style_card = {
    width: '14rem',
    border: "none",
  };
  const style_body = {
    backgroundColor: "#282c34",
  };
  const style_title = {
    fontSize: 16,
    color: '#ccc',
    paddingBottom: "10px",
    fontWeight: 900,
  };
  const style_link = {
    display: 'block',
    fontSize: 16,
    color: '#ccc',
    textDecoration: "none",
    // paddingLeft: "8px",
  };
  const style_social_icon = {
    filter: "invert(100%)",
    marginBottom: "13px",
    marginRight: "13px",
  };

  return (
    <>
      <hr style={{color: "white", marginTop: "80px"}}></hr>
      <Container style={style_container}>
        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            <Card.Title style={style_title}>
              <a target="_new" href="https://www.linkedin.com/company/openvmp"><img style={style_social_icon} alt="LinkedIn" src={social_linkedin} /></a>
              <a target="_new" href="https://github.com/openvmp"><img style={style_social_icon} alt="GitHub" src={social_github} /></a>
              <a target="_new" href="https://twitter.com/openvmp"><img style={style_social_icon} alt="Twitter" src={social_twitter} /></a>
            </Card.Title>
          </Card.Body>
        </Card>

        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            <Card.Title style={style_title}>Resources</Card.Title>
            <Card.Text>
              {/* <Link style={style_link} to="/tutorials">Tutorials</Link> */}
              <a style={style_link} href="https://github.com/openvmp/openvmp/tree/main/docs">Tutorials</a>
              {/* <Link style={style_link} to="/integrations">Integrations</Link> */}
              {/* <Link style={style_link} to="/partners">Partners</Link> */}
              <Link style={style_link} to="/support">Support</Link>
            </Card.Text>
          </Card.Body>
        </Card>

        <Card bg="dark" style={style_card}>
          <Card.Body style={style_body}>
            <Card.Title style={style_title}>Project</Card.Title>
            <Card.Text>
              {/* <Link style={style_link} to="/source_code">GitHub</Link> */}
              <a style={style_link} href="https://github.com/openvmp/openvmp/">GitHub</a>
              <Link style={style_link} to="/investors">Investors</Link>
              <Link style={style_link} to="/about">About Us</Link>
              <Link style={style_link} to="/support">Contact</Link>
            </Card.Text>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default Footer;
