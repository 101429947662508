import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import photo_roman from '../assets/images/roman_kuzmenko.jpg';

function About() {
  const style_container = {
    paddingTop: "40px",
    paddingBottom: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    // width: '18rem',
  };
  const style_card = {
    width: '18rem',
    // border: "none",
  };
  const style_body = {
    padding: "25px",
    // backgroundColor: "#282c34",
  };
  const style_image = {
    paddingBottom: "20px",
  };
  const style_title = {
    fontSize: 16,
    color: '#ccc',
    paddingBottom: "20px",
    fontWeight: 1200,
  };
  const style_link = {
    display: 'block',
    fontSize: 16,
    color: '#ccc',
    // textDecoration: "none",
  };

  return (
    <div className="App-view">
        Maintainers:
        <Container style={style_container}>
          <Card bg="dark" style={style_card}>
            <Card.Body style={style_body}>
              <Card.Img variant="top" style={style_image} src={photo_roman} />
              <Card.Title style={style_title}>Roman Kuzmenko</Card.Title>
              <Card.Text>
                <a style={style_link} href="https://www.linkedin.com/in/romankuzmenko/">romankuzmenko</a>
                <a style={style_link} href="mailto:kuzmenkor@gmail.com">kuzmenkor@gmail.com</a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Container>
    </div>
  );
}

export default About;
