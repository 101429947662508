import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import robot_don1 from '../assets/images/robot-don1.png';
import robot_oskol1 from '../assets/images/robot-oskol1.png';

function Industries() {
  const style_container = {
    paddingTop: "15px",
    paddingBottom: "40px",
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    // width: '18rem',
  };
  const style_row = {
    alignItems: 'start',
    justifyContent: 'center',
  };
  const style_card = {
    // width: '18rem',
    // border: "none",
    margin: "20px",
  };
  const style_body = {
    padding: "25px",
    // backgroundColor: "#282c34",
  };
  const style_image = {
    paddingBottom: "20px",
  };
  const style_title = {
    fontSize: 16,
    color: '#ccc',
    paddingBottom: "20px",
    fontWeight: 1200,
  };
  const style_link = {
    display: 'block',
    fontSize: 16,
    color: '#ccc',
    // textDecoration: "none",
  };
  const style_text = {
    display: 'block',
    fontSize: 14,
    color: '#ccc',
    // textDecoration: "none",
  };

  return (
    <div className="App-view">
        OpenVMP robots are expected to be used first in the following industries:
        <Container style={style_container}>
          <Row xs={1} md={2} lg={3} style={style_row}>
            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Security
                  </Card.Title>
                  <Card.Text style={style_text}>
                    <ul>
                      <li>Autonomous surveillance</li>
                      <ul>
                        <li>Covers large territories</li>
                        <li>Takes shortcuts</li>
                        <li>Automatically deploys from trucks</li>
                      </ul>
                      <li>Covert surveillance</li>
                      <ul>
                        <li>Lurks in trees, hangs on downspouts</li>
                      </ul>
                      <li>Anomaly detection</li>
                      <ul>
                        <li>Including rogue WiFi detection</li>
                      </ul>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Military
                  </Card.Title>
                  <Card.Text style={style_text}>
                    <ul>
                      <li>Low power consumption allows autonomous behavior over extended periods of time</li>
                      <li>May recharge contactless from high voltage power lines</li>
                      <li>Can lurk in trees for months for surveillance purposes</li>
                      <li>Can carry and deploy payloads</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Media
                  </Card.Title>
                  <Card.Text style={style_text}>
                    <ul>
                      <li>Keep humans out of harm's way</li>
                      <li>Deploy OpenVMP for recordings</li>
                      <ul>
                        <li>Lurks in tree, hangs on poles</li>
                        <li>Deploys from trucks and vans</li>
                      </ul>
                      <li>Stereo vision</li>
                      <li>4 pi steradians (complete spherical view)</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Industrial Facilities and Utilities
                  </Card.Title>
                  <Card.Text style={style_text}>
                    <ul>
                      <li>Generic inspection and maintenance</li>
                      <li>Unique services for pipes and wires</li>
                      <ul>
                        <li>Including cleaning, painting</li>
                      </ul>
                      <li>Unique services for hard to reach and dangerous environments</li>
                      <li>Anomaly detection</li>
                      <ul>
                        <li>Noise, heat, light</li>
                      </ul>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Residential Services
                  </Card.Title>
                  <Card.Text style={style_text}>
                    <ul>
                      <li>Roof inspection and cleaning, gutters cleaning</li>
                      <li>Crawlspace inspection, pest control, radon detection</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Storage, Logistics and Transportation
                  </Card.Title>
                  <Card.Text style={style_text}>
                    <ul>
                      <li>Safety and compliance inspections via telepresence</li>
                      <ul>
                        <li>Vehicles</li>
                        <li>Infrastructure</li>
                      </ul>
                      <li>Climbing shelving systems in warehouses, racks in datacenters</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Construction and Mining
                  </Card.Title>
                  <Card.Text style={style_text}>
                    <ul>
                      <li>Safety and compliance inspections</li>
                      <li>Continuous assessment</li>
                      <li>Replacing humans by future heavier models</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Space Exploration and Mining
                  </Card.Title>
                  <Card.Text style={style_text}>
                    <ul>
                      <li>Non-humanoid robots are perfect in space</li>
                      <li>OpenVMP is designed to defy gravity, for sure performs well in its absence</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card bg="dark" style={style_card}>
                <Card.Body style={style_body}>
                  <Card.Title style={style_title}>
                    Education
                  </Card.Title>
                  <Card.Text style={style_text}>
                    <ul>
                      <li>You've seen this one before</li>
                      <li>Unlike other robots, OpenVMP doesn't just move forward and backward</li>
                      <li>Diversity of movements allows easy implementation and visual observation of more algorithms</li>
                      <ul>
                        <li>Walking, running, even dancing</li>
                        <li>Mimicry of various insects and animals</li>
                      </ul>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
    </div>
  );
}

export default Industries;
