function Investors() {
  return (
    <div className="App-view">
      <p>
        Pre-seed phase.
        Open to offers.
        Please, reach out to <a href="mailto:openvmp@proton.me">openvmp@proton.me</a>.
      </p>
    </div>
  );
}

export default Investors;
