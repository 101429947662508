function Support() {
  return (
    <div className="App-view">
      <p>
        <a href="https://github.com/openvmp/openvmp/issues">GitHub Issues</a> is the most preferred way of communication at the moment.
      </p>
      <p>
        Alternatively, you can try reaching out using <a href="mailto:openvmp@proton.me">openvmp@proton.me</a>.
      </p>
    </div>
  );
}

export default Support;
