import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './views/Home';
import Industries from './views/Industries';
import Technologies from './views/Technologies';
import Solutions from './views/Solutions';
import Products from './views/Products';
import Partners from './views/Partners';
import Support from './views/Support';
import Sources from './views/Sources';
import Investors from './views/Investors';
import About from './views/About';

ReactGA.initialize('G-PH26QTSWFE');
// ReactGA.initialize('348603868');

function App() {
  // ReactGA.pageview(window.location.pathname);
  ReactGA.send("pageview");

  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/products" element={<Products />} />
        <Route path="/solutions" element={<Solutions />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/technologies" element={<Technologies />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/support" element={<Support />} />
        <Route path="/source_code" element={<Sources />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/about" element={<About />} />
        <Route exact path="/" element={<Home />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
